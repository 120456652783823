import React, { useState, useEffect } from "react";
import { getcurrentStatus, DeleteApplication } from "../../api/currentApplications";
import { useHistory } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { getApplicationPaymentForDashboard, getCompiledAmountForDashboard } from "../../utils/getPaymentAmount";
export default function ApplicationListViewCard(props) {
  const { v } = props;
  const history = useHistory();
  const [rejected, setRejected] = useState(false);
  const [isSalesOrderValidated, setIsSalesOrderValidated] = useState(false);
  const [reviewedBy, setReviewedBy] = useState([]);
  const [validatePayments, setValidatePayments] = useState(false);
  useEffect(() => {
    setIsSalesOrderValidated(v?.salesorderValidated);
    setValidatePayments(v?.payment?.event2StripeResponse[0]?.payment_status);
    v.reviewBy ? setReviewedBy(v.reviewBy) : setReviewedBy([]);
    v.rejected ? setRejected(v.rejected) : setRejected(false);
  }, [v]);

  const { roleName = "" } = useUser() || {};

  let { draft } = v.agreement || {};
  let { formData: formData1 } = (draft && draft[2]) || {};
  let activity_number = (formData1 && formData1[0]) || "";

  const callvalue = async () => {
    if (v.applicationStage === 1) {
      history.push(`/applicationPayment/${v._id}`);
    }
    if (v.applicationStage === -1 || v.applicationStage === 0) {
      if (v.formName === "form220") {
        history.push(`/${v.formName}/${v._id}`);
      }
      if (v.formName === "form210") {
        history.push(`/${"form210"}/${v._id}`);
      }
      return;
    }
    localStorage.setItem("headerId", v._id);

    let newRes = await getcurrentStatus(v._id);
    if (newRes.data.data.currentStage === "Applicant Review") {
      history.push(`/applicantView/${v._id}`);
    }

    if (newRes.data.data.currentStage === "Initial Review" || newRes.data.data.currentStage === "Approved") {
      history.push(`/applicationReview/${v._id}`);
    }
    if (newRes.data.data.currentStage === "Validate Payment") {
      history.push(`/applicationPayment/${v._id}`);
    }
    if (
      newRes.data.data.currentStage === "Draft Agreement Assign Signee" ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 9) ||
      newRes.data.data.currentStage === "Validate Signature" ||
      newRes.data.data.currentStage === "Draft Agreement Generation" ||
      newRes.data.data.currentStage === "Draft Final Review" ||
      newRes.data.data.currentStage === "Draft Agreement Review" ||
      newRes.data.data.currentStage === "NCRR Review Draft Agreement"
    ) {
      history.push(`/draftAgreement/${v._id}`);
    }
    if (newRes.data.data.currentStage === "Engineering Review") {
      history.push(`/review/${v._id}`);
    }
    if (
      newRes.data.data.currentStage === "Compile License Agreement" ||
      newRes.data.data.currentStage === "Upload Insurance" ||
      (newRes.data.data.currentStage === "Payment" && newRes.data.data.id === 18) ||
      newRes.data.data.currentStage === "Validate Uploaded Insurance" ||
      newRes.data.data.currentStage === "Compile Agreement Assign Signee" ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 20) ||
      (newRes.data.data.currentStage === "Signature" && newRes.data.data.id === 24) ||
      newRes.data.data.currentStage === "Validate Fee, Insurance and Agreement" ||
      newRes.data.data.currentStage === "Upload Letter and Validate" ||
      newRes.data.data.currentStage === "Validate and Sign" ||
      newRes.data.data.currentStage === "Final Release"
    ) {
      history.push(`/compiledAgreement/${v._id}`);
    }
    if (newRes.data.data.currentStage === "Payment" && newRes.data.data.id === 18) {
      history.push(`/compiledAgreement/${v._id}`);
    }
    if (newRes.data.data.currentStage === "Completed" && v.applicationStage != -1) {
      history.push(`/finalRelease/${v._id}`);
    }
  };

  let { comments } = v || {};

  let { applicationComments, engineeringComments } = comments || {};
  let { applicant: appicantapplicationComments, resolved: resolvedapplicationComments } = applicationComments || {};

  let { team: teamengineeringComments, approver: approverengineeringComments, applicant: appicantengineeringComments, resolved: resolvedengineeringComments } = engineeringComments || {};

  let applicantCommentsId = appicantapplicationComments && appicantapplicationComments.map((aID) => aID.questionID);

  let resolveCommentId = resolvedapplicationComments && resolvedapplicationComments.map((rID) => rID.questionID);

  let applicantCommentsIdE = appicantengineeringComments && appicantengineeringComments.map((aID) => aID.questionID);
  let approverCommentIdE = approverengineeringComments && approverengineeringComments.map((aID) => aID.questionID);
  let teamCommentIdE = teamengineeringComments && teamengineeringComments.map((tID) => tID.questionID);

  let resolveCommentIdE = resolvedengineeringComments && resolvedengineeringComments.map((rID) => rID.questionID);

  let AppComm = [...new Set(applicantCommentsId)].length - resolveCommentId.length || [];
  let EnggComm = [...new Set([...applicantCommentsIdE, ...approverCommentIdE, ...teamCommentIdE])].length - resolveCommentIdE.length || [];
  let applicationCommentsId = appicantapplicationComments && appicantapplicationComments.map((d) => d.questionID);
  let uniqueNum1 = [...new Set(applicationCommentsId)];

  let applicationResolvedId = resolvedapplicationComments && resolvedapplicationComments.map((d) => d.questionID);
  let uniqueNum2 = [...new Set(applicationResolvedId)];

  const isEqual = uniqueNum1.length === uniqueNum2.length && uniqueNum1.every((val) => uniqueNum2.includes(val));

  let EnggCommId1 = [...new Set(applicantCommentsIdE)];
  let EnggCommId2 = [...new Set(approverCommentIdE)];
  let EnggCommId3 = [...new Set(teamCommentIdE)];
  const EnggCommId4 = [...new Set(EnggCommId1.concat(EnggCommId2, EnggCommId3))];
  const isEqualEngg = EnggCommId4.length === resolveCommentIdE.length && EnggCommId4.every((val) => resolveCommentIdE.includes(val));

  const DeleteId = async () => {
    const id = localStorage.getItem("deleteId");
    const obj1 = {
      applicationID: id,
    };
    try {
      await DeleteApplication(obj1);
      localStorage.removeItem("deleteId");
    } catch (error) {
      console.log(error);
    }
    window.location.reload();
  };

  activity_number = activity_number.toString();

  const getTimeDiff = (date = "") => {
    const specificDate = new Date(date);
    const currentDate = new Date();
    const timeDiffInMs = currentDate.getTime() - specificDate.getTime();
    const timeDiffInSeconds = timeDiffInMs / 1000;
    const timeDiffInMinutes = timeDiffInSeconds / 60;
    const timeDiffInHours = timeDiffInMinutes / 60;
    let timeInDays, timeInRemainingHours;
    if (timeDiffInHours >= 24) {
      timeInDays = Math.floor(timeDiffInHours / 24);
      timeInRemainingHours = timeDiffInHours % 24;
      return (
        <>
          <p className="m-0">{date && date.slice(0, 10)} </p>
          <p
            style={{
              fontSize: "12px",
              fontWeight: 600,
              opacity: "0.8",
              color: "red",
            }}
          >
            ({Math.round(timeInDays)}.{Math.round(timeInRemainingHours)}d)
          </p>
        </>
      );
    } else {
      if (timeDiffInHours) {
        return (
          <>
            <p className="m-0">{date && date.slice(0, 10)} </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: 600,
                opacity: "0.8",
                color: "red",
              }}
            >
              (0.{Math.round(timeDiffInHours)}d)
            </p>
          </>
        );
      } else {
        return "";
      }
    }
  };
  let lastUpdated;
  try {
    lastUpdated = v.laststageUpdated[v.laststageUpdated.length - 1].date;
  } catch (error) {
    lastUpdated = false;
  }

  return (
    <div>
      <div className="row position-relative">
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Utility Application</small>

          <p className="m-0">#{v.ApplicationTitle && v.ApplicationTitle ? v.ApplicationTitle : v._id}</p>
        </div>
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">HNTB File#</small>

          <p className="m-0">{v.HNTBFileNumber}</p>
        </div>
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Activity Number</small>
          {/* Activity 1  */}
          <p className="m-0">{activity_number ? activity_number : ""}</p>
        </div>
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Application Type</small>

          <p className="m-0">{v.formName}</p>
        </div>

        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Applicant</small>
          <p className="m-0">{v.applicantDetails && v?.applicantDetails?.facilityCompany?.name}</p>
        </div>
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Primary Contact</small>
          <p className="m-0">{v.applicantDetails && v?.applicantDetails?.consultantContact?.name}</p>
        </div>
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Created</small>
          {getTimeDiff(v.submittedOn)}
        </div>
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Age</small>
          <p className="m-0">{getTimeDiff(lastUpdated ? lastUpdated : v.lastModifiedOn)}</p>
        </div>
        {roleName === "Approving Accountant" && (
          <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
            <small className="text-muted d-md-none d-block">Payment</small>
            <p className="m-0">
              <ol className="m-0 px-3">
                {getApplicationPaymentForDashboard(v) && <li className="mb-0">{getApplicationPaymentForDashboard(v)}</li>}
                {getCompiledAmountForDashboard(v) && <li className="mb-0">{getCompiledAmountForDashboard(v)}</li>}
              </ol>
            </p>
          </div>
        )}
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Comments</small>

          <p className="m-0">{EnggComm + AppComm} Open comments</p>
        </div>
        <div onClick={callvalue} style={{ cursor: "pointer" }} className="col-md-1 mb-2">
          <small className="text-muted d-md-none d-block">Status</small>
          <p className="pe-3">
            {rejected ? "Rejected" : null}
            {v.applicationStage === -1 ||
            //v.applicationStage === 1 ||
            v.applicationStage === 0 ? (
              "Draft Application"
            ) : v.applicationStage === 1 ? (
              <>
                {" "}
                Payment <span className="value">(Application Payment) </span>
              </>
            ) : v.applicationStage === 2 ? (
              <>
                {" "}
                Applicant Review <span className="value">(Initial Review) </span>
              </>
            ) : v.applicationStage === 4 || v.applicationStage === 5 ? (
              "Engineering Review"
            ) : v.applicationStage === 3 && !rejected ? (
              <>
                {" "}
                Applicant Review <span className="value">(Initial Review) </span>
              </>
            ) : v.applicationStage === 6 ? (
              "Engineering Review"
            ) : v.applicationStage === 7 ? (
              <>
                Draft Agreement <span className="value">(Assign Signee) </span>
              </>
            ) : v.applicationStage === 8 ? (
              <>
                {" "}
                Draft Agreement <span className="value">(Signature) </span>
              </>
            ) : v.applicationStage === 9 ? (
              <>
                {" "}
                Draft Agreement <span className="value">(Validate Signature) </span>
              </>
            ) : v.applicationStage === 10 ? (
              <>
                {" "}
                Draft Agreement <span className="value">(Agreement Generation) </span>
              </>
            ) : v.applicationStage === 11 ? (
              <>
                {" "}
                Draft Agreement <span className="value">(NCRR Review) </span>
              </>
            ) : v.applicationStage === 12 ? (
              <>
                {" "}
                Draft Agreement <span className="value">(NS Review) </span>
              </>
            ) : v.applicationStage === 13 ? (
              <>
                {" "}
                Draft Agreement <span className="value">(HNTB Review) </span>
              </>
            ) : v.applicationStage === 14 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(License Agreement) </span>
              </>
            ) : v.applicationStage === 15 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Upload Insurance) </span>
              </>
            ) : v.applicationStage === 16 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Validate Uploaded Insurance) </span>
              </>
            ) : v.applicationStage === 17 && validatePayments != "paid" ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Payment) </span>
              </>
            ) : v.applicationStage === 17 && validatePayments === "paid" ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Validate payment) </span>
              </>
            ) : v.applicationStage === 18 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Assign Signee) </span>
              </>
            ) : v.applicationStage === 19 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Signature) </span>
              </>
            ) : v.applicationStage === 20 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Validate Fee, Insurance and Agreement) </span>
              </>
            ) : v.applicationStage === 21 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Validate and Sign By V.P) </span>
              </>
            ) : v.applicationStage === 22 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Upload Letter and Validate) </span>
              </>
            ) : v.applicationStage === 23 ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Validate and Sign By Operating Signee) </span>
              </>
            ) : v.applicationStage === 24 && isSalesOrderValidated ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Final Release) </span>
              </>
            ) : v.applicationStage === 24 && !isSalesOrderValidated ? (
              <>
                {" "}
                Compile Agreement <span className="value">(Validate Signature) </span>
              </>
            ) : v.applicationStage === 25 ? (
              "Final Release"
            ) : v.applicationStage === 26 ? (
              <>
                Application Payment <span className="value">(Validate payment) </span>
              </>
            ) : null}
          </p>
        </div>
        <div onClick={callvalue} style={{ cursor: "pointer" }} className={`${v.applicationStage === -1 ? "col-md-1 mb-2" : roleName === "Approving Accountant" ? "col-md-1 mb-2" : "col-md-2 mb-2"}`}>
          <small className="text-muted d-md-none d-block">Action</small>
          <p>
            {}
            {v.applicationStage === 6 && teamengineeringComments.length > 0 && !isEqualEngg && roleName === "Engineering Representative" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Comment Reply Awaited
              </span>
            ) : null}
            {v.applicationStage === 6 && (teamengineeringComments.length > 0 || approverengineeringComments.length > 0) && !isEqualEngg && roleName === "Approving Representative" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Comment Reply Awaited
              </span>
            ) : null}
            {v.applicationStage === 6 && appicantengineeringComments.length > 0 && !isEqualEngg && roleName === "applicant" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Comment Reply Awaited
              </span>
            ) : null}
            {v.applicationStage === 3 && appicantapplicationComments.length > 0 && !isEqual && (roleName === "applicant" || roleName === "Approving Representative") ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Comment Reply Awaited
              </span>
            ) : null}
            {v.applicationStage === 17 &&
            !v.event2paymentOn &&
            /* && (check != "Check") */
            roleName === "applicant" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Waiting for Payment
              </span>
            ) : null}
            {v.applicationStage === 17 &&
            validatePayments === "paid" &&
            /* && (check != "Check") */
            roleName === "Approving Accountant" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative text-wrap">
                Awaiting for your Approval
              </span>
            ) : null}

            {v.applicationStage === 1 && !v.event1paymentOn && roleName === "applicant" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Waiting for Payment
              </span>
            ) : null}

            {v.applicationStage === 15 && roleName === "applicant" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Waiting for insurance
              </span>
            ) : null}

            {(v.applicationStage === 7 || v.applicationStage === 8 || v.applicationStage === 18 || v.applicationStage === 19) && roleName === "applicant" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Waiting for Signature
              </span>
            ) : null}

            {((v.applicationStage === 1 && (v.event1paymentType === "Check" || v.event1paymentType === "ACH")) ||
              (v.applicationStage === 17 && (v.event2paymentType === "Check" || v.event2paymentType === "ACH"))) &&
            roleName === "Approving Accountant" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative text-wrap">
                Awaiting for your Approval
              </span>
            ) : null}

            {((v.applicationStage === 6 &&
              (reviewedBy && reviewedBy.includes("Approving Representative") !== true ? true : false) &&
              (reviewedBy && reviewedBy.includes("Engineering Representative") === true ? true : false) &&
              (teamengineeringComments.length == 0 || approverengineeringComments.length == 0 || appicantengineeringComments.length == 0 || isEqualEngg)) ||
              v.applicationStage === 2 ||
              (v.applicationStage === 3 && (appicantapplicationComments.length === 0 || isEqual)) ||
              v.applicationStage === 11 ||
              v.applicationStage === 16 ||
              v.applicationStage === 20) &&
            roleName === "Approving Representative" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Awaiting for your Approval
              </span>
            ) : null}

            {((v.applicationStage === 6 &&
              (reviewedBy.includes("Engineering Representative") !== true ? true : false) &&
              (teamengineeringComments.length == 0 || approverengineeringComments.length == 0 || appicantengineeringComments.length == 0) &&
              isEqualEngg) ||
              v.applicationStage === 9 ||
              v.applicationStage === 10 ||
              v.applicationStage === 13 ||
              v.applicationStage === 14 ||
              (v.applicationStage === 24 && isSalesOrderValidated === true)) &&
            roleName === "Engineering Representative" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Awaiting for your Approval
              </span>
            ) : null}
            {v.applicationStage === 26 && roleName === "Approving Representative" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Awaiting for your Approval
              </span>
            ) : null}
            {v.applicationStage === 24 && roleName === "Approving Representative" && isSalesOrderValidated === false ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Awaiting for your Approval
              </span>
            ) : null}

            {v.applicationStage === 23 && roleName === "Operating Signee" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Awaiting for your Approval
              </span>
            ) : null}
            {v.applicationStage === 26 && roleName === "Approving Accountant" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative text-wrap">
                Awaiting for your Approval
              </span>
            ) : null}

            {((v.applicationStage === 6 && (teamengineeringComments.length == 0 || approverengineeringComments.length == 0 || appicantengineeringComments.length == 0 || isEqualEngg)) ||
              v.applicationStage === 12 ||
              v.applicationStage === 22) &&
            roleName === "Operating Representative" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Awaiting for your Approval
              </span>
            ) : null}
            {((v.applicationStage === 6 && (teamengineeringComments.length == 0 || approverengineeringComments.length == 0 || appicantengineeringComments.length == 0 || isEqualEngg)) ||
              v.applicationStage === 12 ||
              v.applicationStage === 22) &&
            roleName === "Operating Engineering Representative" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Awaiting for your Approval
              </span>
            ) : null}

            {v.applicationStage === 21 && roleName === "Signing Representative" ? (
              <span style={{ textDecoration: "underline", color: "blue" }} className="btn sm-btn btn-link px-0 position-relative">
                Awaiting for your Signature
              </span>
            ) : null}
          </p>
        </div>
        <div className="col-md-1 mb-2  d-flex justify-content-end">
          {v.applicationStage === -1 && (
            <>
              <div className="dropdown" onClick={() => localStorage.setItem("deleteId", v._id)}>
                <span className="toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i style={{ fontSize: "15px" }} className="fa">
                    &#xf142;
                  </i>
                </span>
                <div className="dropdown-menu dropdown-menu-lefts" aria-labelledby="dropdownMenuButton1" x-placements="left">
                  <div>
                    <a className="dropdown-item discard" data-bs-toggle="modal" data-bs-target="#exampleModals">
                      Discard application
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="exampleModals" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabels" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content" style={{ zIndex: "9999" }}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabels">
                        Delete Application
                      </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">Are you sure you want to Delete this Application</div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        NO
                      </button>
                      <button onClick={DeleteId} type="button" className="btn btn-danger" data-bs-toggle="modal">
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <hr className="mx-0" />
      </div>
    </div>
  );
}
